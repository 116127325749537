<template>
  <div class="message-list">
    <div class="list_bar">
      <div class="item" @click="typeChange(1)">
        <img
          src="../../../assets/images/s_m_icon.png"
          alt=""
          srcset=""
        />系统消息
        <div :class="{ line: true, system_color: type == 1 }"></div>
      </div>
      <div class="item" @click="typeChange(2)">
        <img
          src="../../../assets/images/message_icon.png"
          alt=""
          srcset=""
        />审核消息
        <div :class="{ line: true, examine_color: type == 2 }"></div>
      </div>
    </div>
    <div v-show="type == 1">
      <div v-show="list.length">
        <div
          class="system-item"
          @click="toDetail(item)"
          v-for="(item, index) in list"
          :key="index"
        >
          <p>
            {{ item.title }}
          </p>
          <p>{{ item.create_time }}</p>
          <div>
            {{ item.content }}
          </div>
        </div>
        <p class="noMore" v-show="listNoMore">没有更多了~</p>
      </div>
      <div class="empty" v-show="!list.length">
        <div class="icon"></div>
        <p class="tips">暂无系统消息</p>
      </div>
    </div>
    <div v-show="type == 2">
      <div v-show="modifyPagelist.length">
        <div
          class="message-item"
          @click="toDetail(item)"
          v-for="(item, index) in modifyPagelist"
          :key="index"
        >
          <div class="top">
            <div class="title">
              <img
                src="../../../assets/images/edit_icon.png"
                alt=""
                srcset=""
              />{{
                item.operationType == 1
                  ? "调课"
                  : item.operationType == 2
                  ? "请假"
                  : "预约"
              }}
            </div>
            <div
              :class="{
                status: true,
                wait_color:
                  item.approvalStatus == 0 || item.approvalStatus == 4,
                refuse_color:
                  item.approvalStatus == 3 || item.approvalStatus == 2,
                pass_color: item.approvalStatus == 1,
              }"
            >
              {{
                item.approvalStatus == 0
                  ? "待处理"
                  : item.approvalStatus == 1
                  ? "已通过"
                  : item.approvalStatus == 2
                  ? "未通过"
                  : item.approvalStatus == 3
                  ? "课时已被调整"
                  : "已撤回"
              }}
            </div>
          </div>
          <div class="info">
            <ul>
              <li class="title">{{ item.groupName }}</li>
              <li>
                <div>
                  <img src="../../../assets/images/personnel.png" alt="" />
                </div>
                {{ item.teacherName }}
              </li>
              <li>
                <div>
                  <img
                    src="../../../assets/images/clock.png"
                    alt=""
                  />原上课时间
                </div>
                {{ item.oldDateTime }}
              </li>
              <li>
                <div>
                  <img
                    src="../../../assets/images/clock.png"
                    alt=""
                  />新上课时间
                </div>
                {{ item.newDateTime }}
              </li>
            </ul>
          </div>
          <p class="time">{{ item.createTime }}</p>
        </div>
        <p class="noMore" v-show="noMore">没有更多了~</p>
      </div>
      <div class="empty" v-show="!modifyPagelist.length">
        <div class="icon"></div>
        <p class="tips">暂无审核消息</p>
      </div>
    </div>
  </div>
</template>
<script>
import { messageList, modifyPage } from "../../../api/mentor";
import EventBus from "../../../api/EventBus";
var end = 1;
export default {
  data() {
    return {
      type: 1,
      listNoMore: false,
      noMore: false,
      dataForm: {
        pageNum: 1,
        pageSize: 10,
      },
      listDataForm: {
        pageNum: 1,
        pageSize: 10,
      },
      list: [],
      modifyPagelist: [],
    };
  },
  methods: {
    toDetail(i) {
      if (this.type == 1) {
        this.$router.push({
          name: "mentor-message-messageDetail",
          params: {
            content: i.content,
            time: i.create_time,
            title: i.title,
            type: this.type,
          },
        });
      } else {
        this.$router.push({
          name: "mentor-message-messageDetail",
          params: { id: i.id, type: this.type },
        });
      }
    },
    typeChange(type) {
      this.type = type;
    },
    async getList() {
      const res = await messageList(this.listDataForm);
      if (res.list.length < 10) {
        this.listNoMore = true;
      }
      res.list.forEach((i) => {
        this.list.push(i);
      });
    },
    async getModifyPage() {
      const res = await modifyPage(this.dataForm);
      if (res.list.length < 10) {
        this.noMore = true;
      }
      res.list.forEach((i) => {
        this.modifyPagelist.push(i);
      });
    },
    onScrollg() {
      let that = this;
      //可滚动容器的高度
      let innerHeight = document.querySelector("#app").clientHeight;
      //屏幕尺寸高度
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      // 变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.clientHeight || document.body.scrollHeight;
      //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      if (innerHeight < scrollHeight + scrollTop + 1 && end != 0) {
        //加载更多操作
        this.next = "加载中";
        end = 0;
        this.pageNum++;
        console.log("下拉加载");
        //执行函数

        setTimeout(function () {
          if (that.type == 1) {
            if (!that.listNoMore) {
              that.listDataForm.pageNum++;
              console.log(that.listNoMore);
              that.getList();
            }
          } else {
            if (!that.noMore) {
              that.dataForm.pageNum++;
              that.getModifyPage();
            }
          }
          end = 1;
        }, 1000);
      }
    },
  },
  created() {
    document.title = "通知消息";
    console.log(this.$refs.btnBar);
    if (this.$route.params.type == 2) {
      this.type = 2;
      EventBus.$emit("routerChange", 2);
    }
    this.getList();
    this.getModifyPage();
  },

  mounted() {
    var that = this;
    window.addEventListener("scroll", that.onScrollg);
  },
  destroyed() {
    var that = this;
    window.removeEventListener("scroll", that.onScrollg);
  },
};
</script>
<style lang="stylus" scoped>
@import '../../../common.styl';
@import '../../../theme.styl';

.message-list {
  .list_bar {
    height: 72px;
    border-top: 1px solid #EEEEEE;
    margin-bottom: 10px;
    display: flex;

    .item {
      width: 50%;
      height: 100%;
      background-color: #fff;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      color: #0E0E0E;
      position: relative;

      img {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        margin-left: 33px;
      }

      .line {
        width: 110px;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .system_color {
        background-color: #2E9BFF;
      }

      .examine_color {
        background: #E14247;
      }
    }

    div:nth-child(1) {
      border-right: 1px solid #EEEEEE;
    }
  }

  .system-item {
    padding: 24px 15px 10px;
    // height: 117px;
    background-color: #fff;
    margin-bottom: 1px;

    p {
      font-weight: 700;
      font-size: 16px;
      color: #0E0E0E;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      color: #AAAAAA;
      margin-top: 5px;
    }

    div {
      height: 44px;
      margin-top: 6px;
      margin-bottom: 1px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #858585;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
    }
  }

  .message-item {
    background-color: #fff;
    margin-top: 1px;
    padding-bottom: 22px;

    .top {
      box-sizing: border-box;
      height: 58px;
      display: flex;
      justify-content: space-between;
      padding-top: 25px;
      padding: 25px 16px 0 18px;

      .title {
        font-weight: 700;
        font-size: 16px;
        color: #333333;
        position: relative;
        top: -8px;

        img {
          width: 22px;
          height: 22px;
          position: relative;
          top: 4px;
        }
      }

      .status {
        font-weight: 700;
        font-size: 14px;
      }

      .pass_color {
        color: pass;
      }

      .refuse_color {
        color: refuse;
      }

      .wait_color {
        color: wait;
      }
    }

    .info {
      width: 316px;
      background: #F2F6F9;
      border-radius: 8px;
      position: relative;
      left: 43px;

      ul {
        padding: 10px 15px;

        li {
          line-height: 25px;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          display: flex;
        }

        div {
          display: block;
          // width: 16px;
          margin: auto 0;
          margin-right: 16px;

          img {
            width: 16px;
            height: 16px;
            opacity: 0.7;
            position: relative;
            top: 3px;
            margin-right: 2px;
          }
        }

        .title {
          color: #0E0E0E;
          font-weight: 600;
          line-height: 30px;
          font-size: 17px;
        }
      }
    }

    .time {
      position: relative;
      left: 43px;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      margin-top: 20px;
    }
  }

  .noMore {
    margin: 20px 0 40px 0;
    text-align: center;
  }

  .empty {
    padding-top: 173px;

    .icon {
      width: 132px;
      height: 116px;
      margin: 0 auto;
      bgFill();
      background-image: url('./images/Frame-1.png');
    }

    .tips {
      padding-top: 32px;
      text-align: center;
      font-weight: 400;
      font-size: 15px;
      color: #999999;
      line-height: 22px;
    }
  }
}
</style>